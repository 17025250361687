<template>
  <div id="app">
    <div class="container">
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
          <router-link to="/" class="navbar-brand"><b>{{ cname }}</b></router-link>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link">网站首页</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/product" class="nav-link">产品中心</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link">关于我们</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link">联系我们</router-link>
              </li>
            </ul>
            <div class="d-flex">
              咨询热线：<b>{{ cphone }}</b>
            </div>
          </div>
        </div>
      </nav>

      <div class="banner"></div>

      <div class="content">
        <div class="row">
          <div class="col-9">
            <router-view :cname="cname" :caddress="caddress" :cphone="cphone"></router-view>
          </div>
          <div class="col-3">
            <h2 class="column-title">公司产品</h2>
            <ul class="list-group list-group-flush list-group-numbered">
              <li class="list-group-item">翡翠</li>
              <li class="list-group-item">沉香</li>
              <li class="list-group-item">蜜蜡</li>
              <li class="list-group-item">和田玉</li>
              <li class="list-group-item">琥珀</li>
              <li class="list-group-item">黄花梨</li>
            </ul><br/>
            <h2 class="column-title">联系我们</h2>
            <div class="alert alert-light" role="alert">
              <p><b>地址：</b>{{ caddress }}<br/>
                <b>电话：</b>{{ cphone }}</p>
              <hr>
            </div>
          </div>

        </div>
      </div>

      <footer class="text-center footer">
        {{ cname }} <b>地址</b>：{{ caddress }} <br/><b>电话</b>：{{ cphone }}  <b>ICP备案号</b>：<a href="https://beian.miit.gov.cn">{{ cicp }}</a>
      </footer>

    </div>
  </div>
</template>



<script>
    import GlobalOption from './GlobalOption.vue'
    export default {
        data () {
            return {
                //使用
                cname:  GlobalOption.cname,
                cphone: GlobalOption.cphone,
                caddress: GlobalOption.caddress,
                cicp: GlobalOption.cicp
            }
        }
    }
</script>

<style>
a {
  text-decoration: none !important;
}
.footer {
  border-top: 1px solid #dadada;
  padding: 40px 0px;
  font-size: 14px;
  margin-top: 40px;
}
.column-title {
  font-size: 18px;
  font-weight: bold;
  background: url(./assets/column.jpg) repeat center bottom;
  background-size: 300px;
  padding: 20px;
  border: 1px solid #eee;
}
.navbar {
  padding: 20px 0px;
}
.product-list img {
  height: 100px;
  object-fit: cover;
}
.content {
  margin: 40px 0px;
}
/*nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}*/
/*
nav a.router-link-exact-active {
  color: #42b983;
}*/

.banner {
  height: 300px;
  background: url("assets/banner.jpg") no-repeat center center;
  background-size: cover;
}

</style>
