<script>
    const cname = '瑞丽市雅沐珠宝店'
    const cphone = '0692-55257785'
    const caddress ='中国（云南）自由贸易试验区德宏片区瑞丽市瑞江路青竹巷12号一楼'
    const cicp = '滇ICP备2023002364号-1'
    // 暴露出这些属性
    export default {
        cname,
        cphone,
        caddress,
        cicp
    }
</script>