<template>
  <div>
    <h2 class="column-title">关于我们</h2>
    <div class="alert alert-light" role="alert">
      <p>{{ cname }} ，是一家集设计、销售、品牌运营为一体的企业，综合实力雄厚的文玩公司，公司在传统文玩产品业务方面实力强大，主要经营天然有琥珀、蜜蜡、翡翠、天然宝石、彩色宝石、以及各类宝石首饰。 公司产品有琥珀、蜜蜡、翡翠，吊坠，手镯，耳环、项链、戒指以及各类订制品配饰。</p>
      <hr>
    </div>
    <h2 class="column-title">产品展示</h2>
    <div class="row gx-3 gy-3 product-list">
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/fc/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">翡翠</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/cx/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">翡翠</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" >
          <img src="../assets/pr/ml/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">蜜蜡</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/hty/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">和田玉</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/hp/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">琥珀</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/hhl/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">黄花梨</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card" >
          <img src="../assets/pr/nh/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">南红</p>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="card">
          <img src="../assets/pr/tz/1.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <p class="card-text text-center">天珠</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['cname'],
  name: 'HomeView',
  components: {
    
  }
}
</script>
